<template>
	<div class="wrap">
		<el-tabs v-model="activeName">
			<el-tab-pane name="first">
				<span slot="label">
					<i class="el-icon-date"></i> 未处理
					<el-badge :value="vuex_waitend" :max="99" v-if="vuex_waitend" />
				</span>
				<my-order></my-order>
			</el-tab-pane>
			<el-tab-pane label="已结算" name="second">
				<my-finished></my-finished>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>
<script>
	import MyOrder from './children/My-Order.vue';
	import MyFinished from './children/My-Finished.vue';
	export default {
		components:{
			MyOrder,
			MyFinished
		},
		data() {
			return {
				activeName: 'first', // 默认未处理页面
				
			};
		},
		mounted() {

		},
		methods: {
			
		},
	};
</script>

<style scoped lang="scss">
	.wrap {
		// height: 100%;
	}
</style>

<!-- 小程序订单 -->
<template>
	<div class="order-box">
		<div class="search-box">
			<el-form :inline="true" :model="searchForm">
				<el-form-item label="手机号">
					<el-input v-model="searchForm.phone" placeholder="支持手机尾号"></el-input>
				</el-form-item>
				<el-form-item label="会员姓名">
					<el-input v-model="searchForm.memberName" placeholder="请输入会员姓名"></el-input>
				</el-form-item>
				<el-form-item label="产品/服务名称">
					<el-input v-model="searchForm.productName" placeholder="请输入产品/服务名称"></el-input>
				</el-form-item>
				<el-form-item label="订单来源">
					<el-select v-model="searchForm.source" placeholder="请选择" clearable>
						<el-option label="小程序" value="1"></el-option>
						<el-option label="线下开单" value="2"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSubmit">查询</el-button>
				</el-form-item>
			</el-form>
			<!-- 操作按钮 -->
			<el-form :inline="true">
				<el-form-item>
					<el-button type="primary" @click="$router.push('/order/statement')">结单</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="orders">
			<table class="order-tb">
				<colgroup>
					<col class="w-45p" />
					<col class="w-20p" />
					<col class="w-10p" />
					<col class="w-10p" />
					<col class="w-15p" />
				</colgroup>
				<thead>
					<tr>
						<th>订单详情</th>
						<th>会员</th>
						<th>状态</th>
						<th>金额</th>
						<th>操作</th>
					</tr>
				</thead>
				<template v-for="(item) in dataTable">
					<!-- 订单信息 -->
					<tbody :key="item.id">
						<tr class="sep-row"></tr>
						<tr class="tr-th">
							<td colspan="5">
								<span class="tit text-gray">订单编号</span>
								<span class="text-black">{{item.order_no}}</span>
								<span class="tit text-gray">订单金额</span>
								<span class="text-black">￥{{item.amount_fee}}</span>
								<span class="tit text-gray">实付金额</span>
								<span class="text-black">￥{{item.actual_fee}}</span>
								<span class="tit text-gray">订单来源</span>
								<span class="text-black" v-if="item.order_origin == 1">小程序</span>
								<span class="text-black" v-if="item.order_origin == 2">线下门店</span>
								<span class="text-black" v-if="item.order_origin == 3">自营H5商城</span>
								<span class="datetime fr padding-right-lg">{{item.created_at}}</span>
							</td>
						</tr>
						<tr class="tr-bd">
							<td>
								<div class="product-list">
									<div class="product-box" v-for="sub in item.order_item" :key="sub.id">
										<el-image class="img" :src="sub.cover" fit="cover">
											<div slot="error" class="el-image__error">{{vuex_project}}</div>
										</el-image>
										<div class="product-name">{{sub.name}}<span v-if="sub.specs" class="text-grey margin-left-sm">[{{sub.specs.sku_values}}]</span></div>
										<div class="count">X{{sub.number}}</div>
										<div class="price text-red width-80 text-right">￥{{sub.totalPrice}}</div>
									</div>
									<!-- 1 上门  2 到店 -->
									<div v-if="item.serve_type == 1" class="padding-lr padding-bottom text-grey">
										【上门】{{item.contact_name}} {{item.phone}} {{item.address}}
									</div>
								</div>
							</td>
							<td>
								<div class="member-box" v-if="item.member_id">
									<el-avatar class="img" :src="item.member_avatar" v-if="item.member_avatar"></el-avatar>
									<el-avatar icon="el-icon-user-solid" v-else></el-avatar>
									<div class="user-name">
										<div>{{item.real_name}}</div>
										<div class="phone">{{item.member_phone|phoneStar}}</div>
									</div>
								</div>
								<div class="text-left text-grey padding-left-lg" v-else>散客</div>
							</td>
							<td>
								<!-- 订单状态 订单支付状态,0未付款 1 已付款  2退款中  3 已退款  4退款失败  5已完成 -->
								<div class="text-center text-blue" v-if="item.order_status == 0">未付款</div>
								<div class="text-center text-green" v-if="item.order_status == 1">已付款</div>
								<div class="text-center text-orange" v-if="item.order_status == 2">退款中</div>
								<div class="text-center text-grey" v-if="item.order_status == 3">已退款</div>
								<div class="text-center text-orange" v-if="item.order_status == 4">退款失败</div>
								<div class="text-center text-grey" v-if="item.order_status == 5">已完成</div>
							</td>
							<td>
								<div class="text-center text-red text-lg">￥{{item.actual_fee}}</div>
								<div class="text-center text-gray" v-if="item.pay_method == 1">微信支付</div>
								<div class="text-center text-gray" v-else-if="item.pay_method == 2">支付宝支付</div>
								<div class="text-center text-gray" v-else-if="item.pay_method == 3">余额支付</div>
								<div class="text-center text-gray" v-else-if="item.pay_method == 4">现金支付</div>
								<div class="text-center text-gray" v-else-if="item.order_origin == 1">在线支付</div>
							</td>
							<td>
								<div class="text-center">
									<template v-if="item.order_status == 1">
										<el-button size="mini" class="btn" @click="$router.push(`/order/statement/${item.id}`)">结单</el-button>
										<!-- <el-button size="mini" class="btn" type="text">取消订单</el-button> -->
									</template>
								</div>
							</td>
						</tr>
					</tbody>
				</template>
			</table>
		</div>
		<div class="text-center margin-top">
			<el-pagination @current-change="pageChange" :current-page="searchForm.page" :page-sizes="vuex_page_sizes" :page-size="searchForm.page_size"
			layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="pageSizeChange">
			</el-pagination>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				searchForm: {
					phone: '',
					memberName: '',
					productName: '',
					source: '',
					page: 1,
					page_size: 10,
					// <!-- 订单状态 订单支付状态,0未付款 1 已付款  2退款中  3 已退款  4退款失败  5已完成 -->
					status: [1, 2, 4]
				},
				total: 0, // 数据总数
				dataTable: []
			};
		},
		mounted() {
			this.loadData()
		},
		methods: {
			loadData() {
				this.$api.order.getOrders(this.searchForm).then(res => {
					res.data.data.forEach(item => {
						item.order_item.forEach(sub => {
							sub.totalPrice = (Number(sub.number) * Number(sub.price)).toFixed(2);
							sub.specs = sub.specs ? JSON.parse(sub.specs) : sub.specs;
						})
					})
					this.dataTable = res.data.data;
					this.total = res.data.count;
				})
			},
			pageSizeChange(size) {
				this.searchForm.page_size = size;
				this.searchForm.page = 1;
				this.loadData()
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.loadData()
			},
			onSubmit() {
				this.searchForm.page = 1
				this.loadData()
			},
		},
	};
</script>

<style scoped lang="scss">
	.order-box {
		width: 99%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.search-box {
			display: flex;
			justify-content: space-between;
		}

		.order-tb {
			border-collapse: collapse;
			width: 100%;
			font-size: 12px;


			// 标题
			thead {
				th {
					height: 32px;
					font-weight: normal;
					line-height: 32px;
					text-align: center;
					background: #f5f5f5;
					color: #666;
				}
			}

			// 空行
			.sep-row {
				height: 20px;
			}

			// 标题行
			.tr-th {
				background: #f5f5f5;
				height: 31px;
				line-height: 31px;
				color: #aaa;
				overflow: hidden;

				td {
					// border-bottom-color: #f5f5f5;
				}
			}

			tbody {
				border-right: 1px solid #000;

				td {
					box-sizing: border-box;
					border: 1px solid #e5e5e5;
				}
			}

			.tit {
				padding-left: 20px;
			}

			.product-list {
				padding-top: 10px;
			}

			.product-box {
				padding: 0px 20px 10px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.img {
					width: 50px;
					height: 50px;
					border-radius: 6px;
					flex: 0 0 auto;
				}

				.product-name {
					flex: 1;
					padding-left: 10px;
					font-size: 18px;
					line-height: 25px;
				}

				.count {
					width: 50px;
					font-size: 14px;
					color: #999;
					text-align: center;
				}
			}

			.member-box {
				padding: 10px 20px;
				display: flex;
				justify-content: center;
				align-items: center;

				.img {
					width: 50px;
					height: 50px;
					border-radius: 6px;
					flex: 0 0 auto;
				}

				.user-name {
					flex: 1 0 auto;
					padding-left: 10px;
					font-size: 18px;

					.phone {
						font-size: 14px;
						color: #999;
					}
				}

			}
		}

		.pagination {
			text-align: center;
			padding-top: 10px;
		}
	}
</style>
